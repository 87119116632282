<template>
  <Table
    class="table-striped-rows"
    style="white-space:nowrap"
    :columns="columns"
    bordered
    :data-source="notices"
    :locale="{
      emptyText: '등록된 공지사항이 없습니다.'
    }"
    size="small"
    :pagination="false"
    :expandIconAsCell="false"
    :expandIconColumnIndex="-1"
    :expanded-row-keys.sync="expandedRowKeys"
    @expandedRowsChange="handleExpandRowKeys"
    expandRowByClick
  >
    <template slot="noticeTitle" slot-scope="value, record">
      <v-chip v-if="record.isUrgent" color="error" small label outlined
        >긴급</v-chip
      >
      {{ value }}
    </template>
    <template slot="expandedRowRender" slot-scope="record">
      <v-textarea :value="record.content" auto-grow readonly solo flat filled>
      </v-textarea>
    </template>
  </Table>
</template>

<script>
import { Table } from 'ant-design-vue';
export default {
  components: {
    Table
  },
  props: {
    notices: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      columns: [
        {
          title: '공지 분류',
          dataIndex: 'category',
          key: 'category',
          width: 80
        },
        {
          title: '공지 일자',
          dataIndex: 'startAt',
          key: 'startAt',
          width: 200
        },
        {
          title: '공지 내용',
          dataIndex: 'title',
          key: 'title',
          scopedSlots: {
            customRender: 'noticeTitle'
          }
        },
        {
          title: '',
          dataIndex: 'function',
          key: 'function',
          width: 10
        }
      ],
      hoverItem: null,
      expandedRowKeys: []
    };
  },
  mounted() {
    this.expandedRowKeys = [parseInt(this.$route.query.nid)];
  },
  methods: {
    // row 클릭시 하나의 row 만 보여지도록 처리합니다.
    handleExpandRowKeys(e) {
      if (e.length > 1) {
        this.expandedRowKeys = e.splice(0, 1);
      }
    }
  }
};
</script>

<style></style>
